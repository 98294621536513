import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Social from './Social';
import CustomContainer from '../../reuseable/CustomContainer';
import { containerConfig } from '../../propsConfig';
import { moveAlphaToEnd } from '../../../utils/hexColorFormat';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '5rem',
    maxWidth: '28rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 'calc(28rem * 0.75)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F9FAFB',
    padding: '20px',
    gap: '24px',
    [theme.breakpoints.up('sm')]: {
      padding: 'calc(20px * 0.75)',
      gap: 'calc(24px * 0.75)',
    },
  },
  section: {
    padding: '1rem',
    borderRadius: '0.5rem',
    borderWidth: '2px',
    [theme.breakpoints.up('sm')]: {
      padding: 'calc(1rem * 0.75)',
    },
  },
  relative: { position: 'relative' },
  bgImgContainer: {
    position: 'relative',
    borderRadius: '0.5rem',
    height: '146px',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(146px * 0.75)',
    },
  },
  bgImg: {
    position: 'absolute',
    borderRadius: '0.5rem',
    width: '100%',
    height: '100%',
  },
  avatarImgContainer: {
    position: 'absolute',
    bottom: '-2.75rem',
    left: '50%',
    borderRadius: '9999px',
    borderWidth: '4px',
    borderColor: '#ffffff',
    height: '90px',
    width: '90px',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('sm')]: {
      bottom: 'calc(-2.75rem * 0.75)',
      height: 'calc(90px * 0.75)',
      width: 'calc(90px * 0.75)',
    },
  },
  avatarImg: {
    position: 'absolute',
    borderRadius: '9999px',
    width: '100%',
    height: '100%',
  },
  bodyContainer: {
    display: 'flex',
    marginTop: '3.5rem',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(3.5rem * 0.75)',
    },
  },
  title: {},
  bio: {
    marginTop: '0.25rem',
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '13px',
    marginLeft: '4px',
    lineHeight: '19px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '3',
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(0.25rem * 0.75)',
      fontSize: 'calc(13px * 0.75)',
      marginLeft: 'calc(4px * 0.75)',
      lineHeight: 'calc(19px * 0.75)',
    },
  },
  socials: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    filter: 'unset !important',
    padding: '0px !important',
    marginTop: '32px',
    [theme.breakpoints.up('sm')]: {
      gap: 'calc(12px * 0.75)',
      marginTop: 'calc(13px * 0.75)',
    },
  },
}));

export default function BiziBioCard({
  bioChild,
  containerClassName,
  containerStyling,
}) {
  const classes = useStyles();

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      <div className={classes.relative}>
        <div className={classes.bgImgContainer}>
          <img
            className={classes.bgImg}
            src={bioChild?.background_image?.url}
            style={{ objectFit: 'cover' }}
            alt={bioChild?.background_image?.media_id}
          />
        </div>
        <div className={classes.avatarImgContainer}>
          <img
            className={classes.avatarImg}
            src={bioChild?.avatar?.url}
            style={{ objectFit: 'cover' }}
            alt={bioChild?.avatar?.media_id}
          />
        </div>
      </div>
      <div className={classes.bodyContainer}>
        {bioChild?.title && (
          <div
            className={classes.title}
            style={{
              fontSize: `${bioChild?.title.style?.size}px`,
              lineHeight: `${bioChild?.title.style.size + 10}px`,
              fontWeight: bioChild?.title.style.weight,
              fontStyle: bioChild?.title.style.italic,
              color: moveAlphaToEnd(bioChild?.title.style.color),
              textAlign: bioChild?.title.style.alignment,
              textDecorationLine: bioChild?.title.style.underline,
            }}
          >
            {bioChild?.title.value}
          </div>
        )}
        {bioChild?.description && (
          <div
            className={classes.bio}
            style={{
              fontSize: `${bioChild?.description.style?.size}px`,
              lineHeight: `${bioChild?.description.style.size + 10}px`,
              fontWeight: bioChild?.description.style.weight,
              fontStyle: bioChild?.description.style.italic,
              color: moveAlphaToEnd(bioChild?.description.style.color),
              textAlign: bioChild?.description.style.alignment,
              textDecorationLine: bioChild?.description.style.underline,
            }}
          >
            {bioChild?.description.value}
          </div>
        )}
        <Social
          containerClassName={classes.socials}
          buttonChild={bioChild?.socials}
          buttonActionChild={bioChild?.action_button}
        />
      </div>
    </CustomContainer>
  );
}
