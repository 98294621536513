/* eslint-disable react-hooks/rules-of-hooks */
import { makeStyles } from '@material-ui/styles';
import { useMutation } from '@tanstack/react-query';
import { setButtonAnalytics } from '../../api/analytics';

const useStyles = makeStyles((theme) => ({
  imgContainer: { position: 'absolute', width: '100%', height: '100%' },
  displayNone: {
    display: 'none',
  },
  mt5: {
    marginTop: '5px',
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(5px * 0.75)',
    },
  },
  mt10: {
    marginTop: '10px',
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(10px * 0.75)',
    },
  },
  mt17: {
    marginTop: '17px',
    [theme.breakpoints.up('sm')]: {
      marginTop: 'calc(17px * 0.75)',
    },
  },
  dropShadow: { filter: 'drop-shadow(0 0 4px rgba(0, 0, 0, 0.12))' },
}));

export const videoConfig = (videoChild) =>
  videoChild?.map((videoBody) => ({
    key: videoBody.id,
    imageContainerProps: {},
    imageContainerClassName: ``,
    imgProps: {},
    imageClassName: ``,
    videoUrl: videoBody.url,
    videoAlt: `${videoBody.provider} ${videoBody.type} Media ID: ${videoBody.media_id}`,
  }));

export const soundConfig = (soundChild) =>
  soundChild?.map((soundBody) => ({
    key: soundBody.id,
    audioUrl: soundBody.url,
  }));

export const imageConfig = (imageChild) =>
  imageChild?.map((imageBody) => ({
    key: imageBody.id,
    imageContainerProps: {},
    imageContainerClassName: ``,
    imgProps: {},
    imageClassName: ``,
    imageUrl: imageBody.url,
    imageAlt: `${imageBody.provider} ${imageBody.type} Media ID: ${imageBody.media_id}`,
  }));

export const carouselConfig = (
  carouselChild,
  slide,
  imageContainerProps,
  moreClassName,
) => {
  const classes = useStyles();

  return carouselChild?.map((carouselBody, idx) => ({
    key: carouselBody.id,
    imageContainerProps,
    imageContainerClassName: `${classes.imgContainer} ${moreClassName} ${
      slide === idx ? '' : classes.displayNone
    }`,
    imgProps: {},
    imageClassName: '',
    imageUrl: carouselBody.url,
    imageAlt: `${carouselBody.provider} ${carouselBody.type} Media ID: ${carouselBody.media_id}`,
  }));
};

export const titleConfig = (titleChild) => {
  const classes = useStyles();

  return {
    title: titleChild?.value,
    titleClassName: classes.mt17,
    titleStyling: {
      alignment: titleChild?.style?.alignment,
      color: titleChild?.style?.color,
      fontWeight: titleChild?.style?.weight,
      fontSize: titleChild?.style?.size,
      isItalic: titleChild?.style?.italic,
      isUnderline: titleChild?.style?.underline,
    },
  };
};

export const textConfig = (textChild) => {
  const classes = useStyles();

  return {
    text: textChild?.value,
    textClassName: textChild?.value ? classes.mt5 : '',
    textStyling: {
      alignment: textChild?.style?.alignment,
      color: textChild?.style?.color,
      fontWeight: textChild?.style?.weight,
      fontSize: textChild?.style?.size,
      isItalic: textChild?.style?.italic,
      isUnderline: textChild?.style?.underline,
    },
  };
};

export const buttonContainerConfig = (
  classes,
  containerStyling,
  buttonsDirection,
) => ({
  buttonContainerClassName: classes,
  buttonContainerStyling: {
    backgroundColor: containerStyling?.background_color,
    flexDirection: buttonsDirection,
  },
});

export const buttonConfig = (buttonChild, buttonsDirection) => {
  const classes = useStyles();
  const mutation = useMutation({
    mutationFn: (id) => setButtonAnalytics(id),
  });

  return buttonChild?.map((buttonBody) => ({
    key: buttonBody.id,
    buttonProps: {
      onClick: () => {
        mutation.mutate(buttonBody.id);
        window.open(`${buttonBody.action?.url}`, '_blank');
      },
    },
    buttonClassName:
      !buttonsDirection || buttonsDirection === 'horizontal'
        ? classes.mt10
        : '',
    buttonTitle: buttonBody.button_text?.value,
    buttonStyling: {
      backgroundColor: buttonBody.button_style?.color,
      buttonPlacement: buttonBody.button_style?.alignment,
      buttonSize: buttonBody.button_style?.size,
      borderRadius: buttonBody.button_style?.radius,
      borderColor: buttonBody.button_style?.border_color,
      alignment: buttonBody.button_text?.style?.alignment,
      color: buttonBody.button_text?.style?.color,
      isItalic: buttonBody.button_text?.style?.italic,
      fontSize: buttonBody.button_text?.style?.size,
      isUnderline: buttonBody.button_text?.style?.underline,
      fontWeight: buttonBody.button_text?.style?.weight,
    },
    buttonIcon: buttonBody.button_style?.icon_style?.icon,
    buttonIconStyling: {
      buttonIconColor: buttonBody.button_style?.icon_style?.color,
      buttonIconPlacement: buttonBody.button_style?.icon_style?.alignment,
    },
  }));
};

export const ctaButtonConfig = (buttonChild) =>
  buttonChild?.map((buttonBody) => ({
    key: buttonBody.id,
    buttonProps: {
      onClick: buttonBody.onClick,
    },
    buttonClassName: '',
    buttonTitle: buttonBody.button_text?.value,
    buttonStyling: {
      backgroundColor: buttonBody.button_style.color,
      buttonPlacement: buttonBody.button_style.alignment,
      buttonSize: buttonBody.button_style.size,
      borderRadius: buttonBody.button_style.radius,
      borderColor: buttonBody.button_style.border_color,
      alignment: buttonBody.button_text.style.alignment,
      color: buttonBody.button_text.style.color,
      isItalic: buttonBody.button_text.style.italic,
      fontSize: buttonBody.button_text.style.size,
      isUnderline: buttonBody.button_text.style.underline,
      fontWeight: buttonBody.button_text.style.weight,
    },
    buttonIcon: buttonBody.button_style.icon_style?.icon,
    buttonIconStyling: {
      buttonIconColor: buttonBody.button_style.icon_style?.color,
      buttonIconPlacement: buttonBody.button_style.icon_style?.alignment,
    },
  }));

export const containerConfig = (containerClassName, containerStyling) => {
  const classes = useStyles();
  return {
    containerClassName: `${classes.dropShadow} ${containerClassName}`,
    containerStyling,
  };
};
