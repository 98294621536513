/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import isMobileDevice from '../../utils/isMobileDevice';
import EmulatorImage from '../../assets/images/emulator.svg';

export default function DesktopView({ props }) {
  const theme = useTheme();

  const textTheme = theme.palette.text.default;

  const useStyles = makeStyles(() => ({
    defaultContainer: {
      display: 'none',
      position: 'absolute',
      alignItems: 'center',
      gap: '20px',
      marginLeft: '4vw',
      marginRight: '4vw',
      top: '50vh',
      height: 'fit-content',
      transform: 'translateY(-50%)',

      ...(theme.breakpoints.up('sm') &&
        !isMobileDevice() && {
          display: 'flex',
        }),

      [theme.breakpoints.up('md')]: {
        marginLeft: '8vw',
        marginRight: '8vw',
        gap: '40px',
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: '12.8vw',
        marginRight: '12.8vw',
        gap: '120px',
      },
    },
    imgContainer: {
      width: 'fit-content',
      height: 'fit-content',
      position: 'relative',
    },
    default: {
      maxHeight: '690px',
      objectFit: 'fill',
    },
    imgChildContainer: {
      position: 'absolute',
      overflowY: 'scroll',
      top: '50%',
      left: '50%',
      height: '91.1%',
      width: '320px',
      borderBottomLeftRadius: '50px',
      borderBottomRightRadius: '50px',
      transform: 'translate(-50%, -47%)',
      backgroundColor: '#FFFCF5FA',
    },
    text: {
      fontSize: '12px',
      lineHeight: '15px',
      color: textTheme,
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '18px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '18px',
        lineHeight: '21px',
      },
    },
    bottomText: {
      marginTop: '24px',
    },
    link: {
      textDecoration: 'underline',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.defaultContainer}>
      <div className={classes.imgContainer}>
        <img className={classes.default} src={EmulatorImage} alt="Background" />
        <div {...props} className={classes.imgChildContainer}>
          {props.children}
        </div>
      </div>
      <div className={classes.text}>
        <div className="">
          The content you are viewing was originally created using a mobile
          application named{' '}
          <a
            className={classes.link}
            href="https://getbizi.io"
            target="_blank"
            rel="noreferrer"
          >
            Bizi
          </a>
          . This application is designed to optimize content for mobile devices,
          ensuring an excellent viewing experience on smaller screens. However,
          it&apos;s important to note that you are currently viewing this
          content in a desktop environment.
        </div>
        <div className={classes.bottomText}>
          While Bizi&apos;s primary design focus is on mobile optimization, the
          content is still fully accessible and functional when accessed from a
          desktop. The rendering you see has been adapted for desktop viewing,
          ensuring that you can engage with the content comfortably, regardless
          of the device you choose to use.
        </div>
      </div>
    </div>
  );
}
