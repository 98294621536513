import React, { Suspense } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from '@tanstack/react-query';
import { useParams, useHistory } from 'react-router';
import { BiArrowBack } from 'react-icons/bi';
import { fetchBusinessCard } from '../../api/businesscard';

import Loading from '../../components/loading/Loading';
import BiziBusinessCardDetails from './BiziBusinessCardDetails';
import ContentLayout from '../../components/layout/ContentLayout';
import PageNotFound from '../404';
import ErrorPage from '../error/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    position: 'relative',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px',
    gap: '24px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: 'calc(20px * 0.6)',
      gap: 'calc(24px * 0.75)',
    },
  },
  header: {
    position: 'sticky',
    top: '-1px',
    background: '#ffffff',
    zIndex: 20,
    width: '100%',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(55px * 0.70)',
    },
  },
  headerTitle: {
    fontSize: '1rem',
    lineHeight: '1.2rem',
    letterSpacing: '-0.025em',
    textAlign: 'center',
  },
  backIcon: {
    position: 'absolute',
    alignItems: 'center',
    left: '6px',
    fontSize: '20px',
    cursor: 'pointer',
  },
}));

export default function BusinessCardDetailsPage() {
  const classes = useStyles();
  const history = useHistory();
  const { host } = window.location;
  const vanityName = host.split('.')[0];
  const { businessCardId: id } = useParams();

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['business_card', id],
    queryFn: async () => {
      const dataBody = await fetchBusinessCard(id, vanityName);
      return dataBody.result;
    },
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
  });

  const handleGoBack = () => {
    history.goBack();
  };

  if (isError) {
    if (error.message.includes('404')) {
      return <PageNotFound />;
    }
    return <ErrorPage error={error} />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <ContentLayout
        style={{
          backgroundColor: '#FFFFFF',
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          data && (
            <div className={classes.root}>
              <header className={classes.header}>
                <BiArrowBack
                  className={classes.backIcon}
                  onClick={handleGoBack}
                />
                <div className={classes.headerTitle}>Business Card</div>
              </header>
              <div className={classes.container}>
                <BiziBusinessCardDetails data={data} />
              </div>
            </div>
          )
        )}
      </ContentLayout>
    </Suspense>
  );
}
