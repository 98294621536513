import React from 'react';
import ReactDOM from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import App from './App';

const container =
  document.getElementById('root') || document.createElement('div');
const root = ReactDOM.createRoot(container);

if (module.hot) {
  root.render(
    <CookiesProvider>
      <App />
    </CookiesProvider>,
  );
} else {
  ReactDOM.hydrateRoot(
    container,
    <CookiesProvider>
      <App />
    </CookiesProvider>,
  );
}
