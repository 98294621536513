/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { moveAlphaToEnd } from '../../utils/hexColorFormat';
import { formatDescription } from '../../utils/formatDescription';

export default function CustomText({
  text,
  textClassName,
  textStyling = {
    fontSize: 13,
    color: '#333333',
  },
}) {
  const color = moveAlphaToEnd(textStyling?.color || '');

  const useStyles = makeStyles((theme) => ({
    text: {
      fontSize: `${textStyling?.fontSize}px`,
      lineHeight: `${(textStyling?.fontSize || 14) * 1.47}px`,
      color,
      [theme.breakpoints.up('sm')]: {
        fontSize: `${textStyling?.fontSize * 0.78}px`,
        lineHeight: `${(textStyling?.fontSize * 0.78 || 14) * 1.47}px`,
      },
    },
    semibold: { fontWeight: 600 },
    normal: { fontWeight: 400 },
    italic: { fontStyle: 'italic' },
    notItalic: { fontStyle: 'normal' },
    underline: { textDecoration: 'underline' },
    noUnderline: { textDecoration: 'none' },
    alignCenter: { textAlign: 'center' },
    alignEnd: { textAlign: 'end' },
    alignStart: { textAlign: 'start' },
    other: {
      fontFamily: 'Poppins',
    },
  }));

  const classes = useStyles();

  const classNameProps = {
    btn_weight:
      textStyling?.fontWeight === 'bold' ? classes.semibold : classes.normal,
    btn_italic: textStyling?.isItalic ? classes.italic : classes.notItalic,
    btn_underline: textStyling?.isUnderline
      ? classes.underline
      : classes.noUnderline,
    btn_alignment:
      textStyling?.alignment === 'center'
        ? classes.alignCenter
        : textStyling?.buttonPlacement === 'end'
        ? classes.alignEnd
        : classes.alignStart,
    btn_other: classes.other,
  };

  return (
    <span
      className={`${textClassName} ${classes.text} ${classNameProps.btn_weight} 
      ${classNameProps.btn_italic} 
      ${classNameProps.btn_underline} 
      ${classNameProps.btn_alignment}
      ${classNameProps.btn_other}`}
    >
      <div dangerouslySetInnerHTML={{ __html: formatDescription(text) }} />
    </span>
  );
}
