import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import isMobileDevice from '../../utils/isMobileDevice';

import ThemeToggle from './ThemeToggle';
import DesktopView from './DesktopView';
import MobileView from './MobileView';

export default function ContentLayout(props) {
  const theme = useTheme();
  const backgroundTheme = theme.palette.background.default;

  const useStyles = makeStyles(() => ({
    root: {
      height: '100%',
      position: 'relative',
      backgroundColor: backgroundTheme,
      ...(theme.breakpoints.up('sm') &&
        !isMobileDevice() && {
          height: '100vh',
        }),
    },
  }));

  const classes = useStyles();

  return (
    <section className={classes.root}>
      <ThemeToggle />
      <DesktopView {...{ props }} />
      <MobileView {...{ props }} />
    </section>
  );
}
