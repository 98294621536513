/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { moveAlphaToEnd } from '../../utils/hexColorFormat';

export default function CustomTitle({
  title,
  titleClassName,
  titleStyling = {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#333333',
  },
}) {
  const color = moveAlphaToEnd(titleStyling?.color || '');

  const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: `${titleStyling?.fontSize}px`,
      lineHeight: `${(titleStyling?.fontSize || 14) * 1.47}px`,
      color,
      [theme.breakpoints.up('sm')]: {
        fontSize: `${titleStyling?.fontSize * 0.78}px`,
        lineHeight: `${(titleStyling?.fontSize * 0.78 || 14) * 1.47}px`,
      },
    },
    semibold: { fontWeight: 600 },
    normal: { fontWeight: 400 },
    italic: { fontStyle: 'italic' },
    notItalic: { fontStyle: 'normal' },
    underline: { textDecoration: 'underline' },
    noUnderline: { textDecoration: 'none' },
    alignCenter: { textAlign: 'center' },
    alignEnd: { textAlign: 'end' },
    alignStart: { textAlign: 'start' },
    other: {
      fontFamily: 'Poppins',
    },
  }));

  const classes = useStyles();

  const classNameProps = {
    btn_weight:
      titleStyling?.fontWeight === 'bold' ? classes.semibold : classes.normal,
    btn_italic: titleStyling?.isItalic ? classes.italic : classes.notItalic,
    btn_underline: titleStyling?.isUnderline
      ? classes.underline
      : classes.noUnderline,
    btn_alignment:
      titleStyling?.alignment === 'center'
        ? classes.alignCenter
        : titleStyling?.buttonPlacement === 'end'
        ? classes.alignEnd
        : classes.alignStart,
    btn_other: classes.other,
  };

  return (
    <div
      className={`${titleClassName} ${classes.title} ${classNameProps.btn_weight} 
      ${classNameProps.btn_italic} 
      ${classNameProps.btn_underline} 
      ${classNameProps.btn_alignment}
      ${classNameProps.btn_other}`}
    >
      {title}
    </div>
  );
}
