import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonContainer } from './Button';
import CustomImage from '../../reuseable/CustomImage';
import CustomContainer from '../../reuseable/CustomContainer';
import CustomTitle from '../../reuseable/CustomTitle';
import CustomText from '../../reuseable/CustomText';
import CustomButton from '../../reuseable/CustomButton';
import {
  buttonConfig,
  buttonContainerConfig,
  containerConfig,
  imageConfig,
  textConfig,
  titleConfig,
} from '../../propsConfig';

export default function Info({
  imageChild,
  titleChild,
  textChild,
  buttonsDirection,
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const useStyles = makeStyles((theme) => ({
    container: {
      padding: '0 !important',
      filter: 'unset !important',
      background: 'unset !important',
      border: 'unset !important',
      marginTop:
        !buttonsDirection || buttonsDirection === 'horizontal' ? '10px' : 0,
      [theme.breakpoints.up('sm')]: {
        marginTop:
          !buttonsDirection || buttonsDirection === 'horizontal'
            ? 'calc(10px * 0.75)'
            : 0,
      },
    },
  }));

  const classes = useStyles();

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      {imageConfig(imageChild)?.map((imageBody) => (
        <CustomImage {...imageBody} />
      ))}
      <CustomTitle {...titleConfig(titleChild)} />
      <CustomText {...textConfig(textChild)} />
      <ButtonContainer
        {...buttonContainerConfig(
          classes.container,
          containerStyling,
          buttonsDirection,
        )}
        addMarginTop={buttonConfig(buttonChild, buttonsDirection).length}
      >
        {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
          <CustomButton key={buttonBody.key} {...buttonBody} />
        ))}
      </ButtonContainer>
    </CustomContainer>
  );
}
