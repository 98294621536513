import React from 'react';
import CustomContainer from '../../reuseable/CustomContainer';
import { containerConfig, buttonConfig } from '../../propsConfig/index.js';
import convertToEmbed from '../../../utils/convertToEmbed.js';

import CustomEmbed from '../../reuseable/CustomEmbed.jsx';
import CustomButton from '../../reuseable/CustomButton';
import CustomIframe from '../../reuseable/CustomIframe.jsx';

export default function Embed({
  embedChild,
  buttonsDirection,
  buttonChild,
  containerClassName,
  containerStyling,
}) {
  const embedValue =
    embedChild.type === 'url'
      ? convertToEmbed(embedChild.value) || embedChild.value
      : embedChild.value;

  return (
    <CustomContainer {...containerConfig(containerClassName, containerStyling)}>
      {/* eslint-disable no-nested-ternary */}
      {embedChild.type === 'url' ? (
        embedValue ? (
          <CustomEmbed embedHtml={embedValue} />
        ) : (
          <CustomIframe url={embedChild.value} />
        )
      ) : (
        <CustomEmbed embedHtml={embedChild.value} />
      )}

      {buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
        <CustomButton key={buttonBody.key} {...buttonBody} />
      ))}
    </CustomContainer>
  );
}
