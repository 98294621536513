import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
  iframeContainer: {
    width: '100%',
    '& iframe': {
      width: '100%',
    },
    '& .vimeo-iframe': {
      height: 'auto',
      aspectRratio: '16 / 9',
    },
    '& blockquote': {
      minWidth: 'unset !important',
      maxWidth: '100% !important',
      margin: '0 auto',
    },
    '& .tiktok-embed': {
      width: '100% !important',
      maxWidth: '100% !important',
      height: '100% !important',
    },
    '& .instagram-media': {
      width: '100% !important',
      margin: '0 auto',
      minWidth: 'unset !important',
      maxWidth: '400px',
      overflow: 'hidden',
    },
  },
}));

export default function CustomEmbed({ embedHtml }) {
  const classes = useStyles();
  const sanitizedHTML = DOMPurify.sanitize(embedHtml, {
    ALLOWED_TAGS: ['iframe', 'blockquote', 'script', 'a', 'section'],
    ALLOWED_ATTR: [
      'src',
      'href',
      'cite',
      'data-video-id',
      'width',
      'height',
      'frameborder',
      'allow',
      'allowfullscreen',
      'style',
      'class',
      'target',
      'charset',
    ],
  });

  useEffect(() => {
    const getScriptSrc = () => {
      if (!sanitizedHTML) return undefined;

      if (sanitizedHTML.includes('tiktok.com')) {
        return 'https://www.tiktok.com/embed.js';
      }
      if (sanitizedHTML.includes('instagram.com')) {
        return 'https://www.instagram.com/embed.js';
      }
      return null;
    };

    const scriptSrc = getScriptSrc();

    if (scriptSrc) {
      const script = document.createElement('script');
      script.src = scriptSrc;
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    return undefined;
  }, [sanitizedHTML]);

  useEffect(() => {
    if (sanitizedHTML && sanitizedHTML.includes('twitter-tweet')) {
      if (window.twttr) {
        window.twttr.widgets.load();
      } else {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        script.onload = () => {
          window.twttr.widgets.load();
        };
        document.body.appendChild(script);
      }
    }
  }, [sanitizedHTML]);

  const updatedEmbedHtml = sanitizedHTML.includes('vimeo.com')
    ? sanitizedHTML.replace('<iframe', '<iframe class="vimeo-iframe"')
    : sanitizedHTML;

  return (
    <div
      className={classes.iframeContainer}
      dangerouslySetInnerHTML={{ __html: updatedEmbedHtml }}
    />
  );
}
