import React, { Suspense, useEffect, useState } from 'react';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import { fetchMicrosite } from '../../api/microsite';

import Loading from '../../components/loading/Loading';
import BiziMicrosite from './BiziMicrosite';

export default function Microsite() {
  const { pathname, host } = window.location;
  const pathnameUrl = pathname.split('/')[1];
  const queryClient = useQueryClient();

  // eslint-disable-next-line no-underscore-dangle
  const [initialData, setInitialData] = useState(window.__INITIAL_DATA__);

  const vanityName = host.split('.')[0];
  const { data: micrositeData } = useQuery({
    queryKey: ['microsite', pathnameUrl],
    queryFn: async () => {
      const data = await fetchMicrosite(pathnameUrl, vanityName);
      return data.result;
    },
    enabled: !initialData, // Only fetch if there is no initial data
  });

  // Hydrate the query client with initial data from the server
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (window.__INITIAL_DATA__) {
      queryClient.setQueryData(
        ['microsite', pathnameUrl],
        // eslint-disable-next-line no-underscore-dangle
        window.__INITIAL_DATA__,
      );
      // eslint-disable-next-line no-underscore-dangle
      setInitialData(window.__INITIAL_DATA__); // Ensure state is updated
    }
  }, [pathnameUrl, queryClient]);

  // Determine the data to pass to the component (either initial or fetched)
  const dataToRender = initialData || micrositeData;

  return (
    <Suspense fallback={<Loading />}>
      <BiziMicrosite data={dataToRender} />
    </Suspense>
  );
}
