function convertToEmbed(url) {
  // YouTube URL conversion
  const youtubeMatch = url.match(
    /(?:youtube\.com(?:\/(?:[^/\n\s]+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|\/)?|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
  );

  if (youtubeMatch && youtubeMatch[1]) {
    return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${youtubeMatch[1]}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
  }

  // If the URL is not valid for either service, return null or an error message
  return null; // or "Unsupported URL";
}

export default convertToEmbed; // Ensure there's a newline at the end of the file
