import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useTheme } from '@material-ui/styles';

export default function Loading() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: { height: '100%' },
      }}
    >
      <CircularProgress
        sx={{
          color: '#CE0A7D',
        }}
        size={60}
        thickness={4}
        color="secondary"
      />
    </Box>
  );
}
