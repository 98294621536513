import React, { Suspense } from 'react';
import Loading from '../../components/loading/Loading';
import BiziBusinessCard from './BiziBusinessCard';

export default function BusinessCardPage() {
  return (
    <Suspense fallback={<Loading />}>
      <BiziBusinessCard />
    </Suspense>
  );
}
