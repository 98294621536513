import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { Switch, Route, StaticRouter, BrowserRouter } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-ui/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Home from './views/home';
import config from './config';
import Microsite from './views/microsite';
import './index.css';
import BusinessCardPage from './views/businessCard';
import BusinessCardDetailsPage from './views/businessCardDetails';
import ColorModeContext from './config/color-context';

Parse.initialize(config.PARSE_APP_ID, '');
Parse.serverURL = config.PARSE_URL;

const palette = {
  default: {
    light: '#FFFFFF',
    dark: '#020427',
    cardDark: '#353652',
    black: '#333333',
  },
};

const getDesignTokens = (mode) => ({
  breakpoints: {
    values: {
      sm: 540, // Small devices (larger phones)
      md: 768, // Medium devices (tablets)
      lg: 1024, // Large devices (desktops)
      xl: 1280, // Extra large devices (large desktops)
      '2xl': 1536, // Extra extra large devices (widescreens)
    },
  },
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          background: {
            default: palette.default.light,
          },
          text: {
            default: palette.default.black,
          },
        }
      : {
          // palette values for dark mode
          background: {
            default: palette.default.dark,
            cardDefault: palette.default.cardDark,
          },
          text: {
            default: palette.default.light,
          },
        }),
  },
});

const queryClient = new QueryClient();

const AppRoutes = () => (
  <Switch>
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/:micrositeId" render={() => <Microsite />} />
    <Route
      exact
      path="/business_cards/:businessCardId"
      render={() => <BusinessCardPage />}
    />
    <Route
      exact
      path="/business_cards/:businessCardId/details"
      render={() => <BusinessCardDetailsPage />}
    />
  </Switch>
);

const App = ({ location }) => {
  const [mode, setMode] = React.useState('dark');

  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  if (location) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <div className="App">
              <StaticRouter location={location} context={{}}>
                <AppRoutes />
              </StaticRouter>
            </div>
          </QueryClientProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <div className="App">
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </div>
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

App.defaultProps = {
  location: null,
};

App.propTypes = {
  location: PropTypes.string,
};

export default App;
