/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFIcon } from '../icons/ReactIcons';
import TouchPointerImage from '../../assets/images/touch-pointer.png';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const useStyles = makeStyles((theme) => ({
  pdf: {
    position: 'relative',
    width: '100%',
    height: '150px',
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      height: `calc(150px * 0.78)`,
    },
  },
  button: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '150px',
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      height: `calc(150px * 0.78)`,
    },
  },
  pdfIcon: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50px',
    height: '50px',
    [theme.breakpoints.up('sm')]: {
      width: `calc(50px * 0.78)`,
      height: `calc(50px * 0.78)`,
    },
  },
  pdfName: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '19px',
    textAlign: 'center',
    color: '#282A37',
    [theme.breakpoints.up('sm')]: {
      fontSize: `calc(13px * 0.78)`,
    },
  },
  pdfContainer: {
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
  },
  gestureOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '30px',
    height: '30px',
    opacity: 0.7,
    zIndex: 10,
  },
  controls: {
    margin: '20px',
    [theme.breakpoints.up('sm')]: {
      margin: `calc(20px * 0.78)`,
    },
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
}));

export default function CustomPdf({ file }) {
  const classes = useStyles();
  const [showGesture, setShowGesture] = useState(true);

  const [numPages, setNumPages] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  function onDocumentLoadSuccess({ pagesNum }) {
    setNumPages(pagesNum);
  }

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderPages = () =>
    Array.from(new Array(numPages), (_, index) => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        renderTextLayer={false}
        width={containerWidth ? containerWidth * 0.9 : 700}
      />
    ));

  const handleOpen = () => {
    setShowGesture(false);
    window.open(
      `https://docs.google.com/gview?url=${file}&embedded=true`,
      '_blank',
    );
  };
  /* eslint-disable react/jsx-no-bind */
  return (
    <div>
      <Button className={classes.button} onClick={handleOpen}>
        <PDFIcon className={classes.pdfIcon} />
        <center ref={containerRef}>
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {renderPages()}
          </Document>
        </center>

        {showGesture && (
          <img
            src={TouchPointerImage}
            alt="Click gesture"
            className={classes.gestureOverlay}
          />
        )}
      </Button>
    </div>
  );
}
/* eslint-enable react/jsx-no-bind */
