/* eslint-disable react/button-has-type */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { ctaButtonConfig } from '../propsConfig';
import CustomButton from '../reuseable/CustomButton';

const useStyles = makeStyles(() => ({
  root: {
    position: 'sticky',
    zIndex: 3,
    bottom: '20px',
  },
}));

export default function CTACard({ saveCard }) {
  const buttonChild = [
    {
      id: '1',
      order: 0,
      button_style: {
        color: 'FFCE0A7D',
        radius: 20,
        alignment: 'center',
      },
      button_text: {
        value: 'Save to Contacts',
        style: {
          alignment: 'center',
          color: 'FFFFFFFF',
          weight: 'bold',
          size: 15,
          italic: false,
          underline: false,
        },
      },
      onClick: () => saveCard(),
    },
  ];

  const classes = useStyles();

  return (
    <section className={classes.root}>
      {ctaButtonConfig(buttonChild)?.map((buttonBody) => (
        <CustomButton {...buttonBody} />
      ))}
    </section>
  );
}
