/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  useButtonIconSelector,
  useIconSelector,
} from '../hooks/useIconSelector';
import { CustomSocialsImage } from './CustomImage';
import { moveAlphaToEnd } from '../../utils/hexColorFormat';

export default function CustomButton(props) {
  const {
    buttonProps,
    buttonTitle,
    buttonClassName,
    buttonStyling = {
      fontSize: 17,
      fontWeight: 'bold',
      color: '#333333',
      backgroundColor: '#f2f2f7',
      borderColor: '#CE0A7D',
      borderSize: '1px',
      borderRadius: '8px',
      buttonSize: 'full',
      buttonPlacement: 'center',
    },
    buttonIcon,
    buttonIconType,
    buttonIconMoreProps,
    buttonIconStyling = {
      buttonIconPlacement: 'left',
      buttonIconColor: '#333333',
    },
    buttonTitleClassName,
  } = props;

  const color = moveAlphaToEnd(buttonStyling?.color || '');
  const borderColor = moveAlphaToEnd(buttonStyling?.borderColor || '');
  const backgroundColor = moveAlphaToEnd(buttonStyling?.backgroundColor || '');
  const buttonIconColor = moveAlphaToEnd(
    buttonIconStyling?.buttonIconColor || '',
  );

  const useStyles = makeStyles((theme) => ({
    button: {
      display: 'flex',
      gap: '0.75rem',
      alignItems: 'center',
      height: '2.75rem',
      paddingLeft: '15px',
      paddingRight: '15px',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        height: 'calc(2.75rem * 0.85)',
        paddingLeft: 'calc(15px * 0.78)',
        paddingRight: 'calc(15px * 0.78)',
        gap: 'calc(0.35rem * 0.75)',
      },
    },
    btnStyles: {
      fontSize: `${buttonStyling?.fontSize}px`,
      lineHeight: `${(buttonStyling?.fontSize || 14) * 1.47}px`,
      color,
      border:
        (buttonStyling?.borderColor !== 'none' && `2px solid ${borderColor}`) ||
        'none',
      borderRadius: buttonStyling?.borderRadius,
      backgroundColor,
      [theme.breakpoints.up('sm')]: {
        fontSize: `${buttonStyling?.fontSize * 0.78}px`,
        lineHeight: `${(buttonStyling?.fontSize * 0.78 || 14) * 1.47}px`,
      },
    },
    iconStyles: {
      height: '1.4rem',
      [theme.breakpoints.up('sm')]: {
        height: 'calc(1.4rem * 0.75)',
      },
    },
    semibold: { fontWeight: 600 },
    normal: { fontWeight: 400 },
    italic: { fontStyle: 'italic' },
    notItalic: { fontStyle: 'normal' },
    underline: { textDecoration: 'underline' },
    noUnderline: { textDecoration: 'none' },
    btnSmall: {
      paddingLeft: '0.35rem',
      paddingRight: '0.35rem',
      gap: 'calc(0.15rem * 0.75)',
      width: '32.5%',
    },
    btnHalf: { width: '50%' },
    btnFull: { width: '100%', flexGrow: 1 },
    btn75: { width: '75%' },
    placementCenter: { marginLeft: 'auto', marginRight: 'auto' },
    placementRight: { marginRight: 'auto' },
    placementLeft: { marginLeft: 'auto' },
    textAlignCenter: { textAlign: 'center' },
    textAlignEnd: { textAlign: 'end' },
    textAlignStart: { textAlign: 'start' },
    flexGrow: { flexGrow: 1 },
    dropShadow: { filter: 'drop-shadow(0 4px 16px rgba(206, 10, 125, 0.05))' },
  }));

  const classes = useStyles();

  const moreIconProps = {
    imageContainerProps: buttonIconMoreProps?.imageContainerProps,
    imageContainerClassName: buttonIconMoreProps?.imageContainerClassName,
    imgProps: buttonIconMoreProps?.imgProps,
    imageClassName: buttonIconMoreProps?.imageClassName,
    imageUrl: useIconSelector(buttonIcon),
    imageAlt: buttonIcon,
  };

  const classNameProps = {
    btn_weight:
      buttonStyling?.fontWeight === 'bold' ? classes.semibold : classes.normal,
    btn_italic: buttonStyling?.isItalic ? classes.italic : classes.notItalic,
    btn_underline: buttonStyling?.isUnderline
      ? classes.underline
      : classes.noUnderline,
    btn_size:
      buttonStyling?.buttonSize === 'small'
        ? classes.btnSmall
        : buttonStyling?.buttonSize === 'half'
        ? classes.btnHalf
        : buttonStyling?.buttonSize === 'full'
        ? classes.btnFull
        : classes.btn75,
    btn_placement:
      buttonStyling?.buttonPlacement === 'center'
        ? classes.placementCenter
        : buttonStyling?.buttonPlacement === 'start'
        ? classes.placementRight
        : classes.placementLeft,
    btnText_alignment:
      buttonStyling?.alignment === 'center'
        ? classes.textAlignCenter
        : buttonStyling?.alignment === 'end'
        ? classes.textAlignEnd
        : classes.textAlignStart,
    btn_border_color: borderColor && classes.dropShadow,
  };

  return (
    <button
      {...buttonProps}
      className={`${buttonClassName}  ${classes.button} ${classes.btnStyles}
        ${classNameProps.btn_weight} 
        ${classNameProps.btn_italic} 
        ${classNameProps.btn_underline} 
        ${classNameProps.btn_size}
        ${classNameProps.btn_placement}
        ${classNameProps.btn_border_color}
      `}
    >
      {buttonIcon &&
        buttonIconStyling.buttonIconPlacement === 'start' &&
        (buttonIconType === 'socials' ? (
          <CustomSocialsImage {...moreIconProps} />
        ) : (
          <div className={classes.iconStyles}>
            {useButtonIconSelector(buttonIcon, buttonIconColor)}
          </div>
        ))}
      <div
        className={`${classes.flexGrow} ${classNameProps.btnText_alignment} ${buttonTitleClassName}`}
      >
        {buttonTitle}
      </div>
      {buttonIcon &&
        buttonIconStyling.buttonIconPlacement === 'end' &&
        (buttonIconType === 'socials' ? (
          <CustomSocialsImage {...moreIconProps} />
        ) : (
          <div className={classes.iconStyles}>
            {useButtonIconSelector(buttonIcon, buttonIconColor)}
          </div>
        ))}
    </button>
  );
}
