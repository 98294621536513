import React, { useState, useEffect } from 'react';

export default function CustomIframe({ url }) {
  const [iframeBlocked, setIframeBlocked] = useState(false);

  useEffect(() => {
    const iframe = document.createElement('iframe');
    iframe.src = url;

    iframe.onload = () => setIframeBlocked(false);
    iframe.onerror = () => setIframeBlocked(true);
  }, [url]);

  return iframeBlocked ? (
    <div>
      <p>
        The content cannot be displayed.
        {' '}
        <a href={url} target="_blank" rel="noopener noreferrer">
          Click here
        </a>
        {' '}
        to view the content directly.
      </p>
    </div>
  ) : (
    <iframe
      src={url}
      title="Embed Url"
      style={{ width: '100%', height: '400px' }}
    />
  );
}
