/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    borderRadius: '0.5rem',
    height: '210px',
    [theme.breakpoints.up('sm')]: { height: '157px' },
  },
  img: {
    position: 'absolute',
    borderRadius: '0.5rem',
    width: '100%',
    height: '100%',
  },
  containerTwo: {
    position: 'relative',
    height: '24px',
    width: '24px',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(1.4rem * 0.75)',
    },
  },
  imgTwo: {
    width: '100%',
    height: '100%',
  },
}));

export default function CustomImage({
  imageContainerProps,
  imageContainerClassName,
  imgProps,
  imageClassName,
  imageUrl,
  imageAlt,
}) {
  const classes = useStyles();
  return (
    <div
      {...imageContainerProps}
      className={`${imageContainerClassName} ${classes.container}`}
    >
      <img
        className={`${imageClassName} ${classes.img}`}
        style={{ objectFit: 'cover' }}
        src={imageUrl}
        alt={imageAlt}
        {...imgProps}
      />
    </div>
  );
}

export function CustomSocialsImage({
  imageContainerProps,
  imageContainerClassName,
  imgProps,
  imageClassName,
  imageUrl = '',
  imageAlt = '',
}) {
  const classes = useStyles();
  return (
    <div
      {...imageContainerProps}
      className={`${imageContainerClassName} ${classes.containerTwo}`}
    >
      <img
        className={`${imageClassName} ${classes.imgTwo}`}
        src={imageUrl}
        alt={imageAlt}
        width={24}
        height={24}
        {...imgProps}
      />
    </div>
  );
}
