/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import CustomButton from '../../reuseable/CustomButton';
import CustomContainer from '../../reuseable/CustomContainer';
import { buttonConfig } from '../../propsConfig';

const useStyles = makeStyles((theme) => ({
  childContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    filter: 'drop-shadow(0 0 8px rgba(0, 0, 0, 0.12))',
    [theme.breakpoints.up('sm')]: {
      gap: 'calc(8px * 0.75)',
    },
  },
  marginNone: {
    marginTop: '0px !important',
  },
  marginTopCustom: {
    marginTop: '16px !important',
  },
}));

export function ButtonContainer({
  children,
  buttonContainerClassName,
  buttonContainerStyling = {
    flexDirection: 'horizontal',
  },
  addMarginTop = false,
}) {
  const classes = useStyles();
  return (
    <CustomContainer
      containerStyling={buttonContainerStyling}
      moreStylingProps={{
        padding: '0px',
        flexDirection:
          buttonContainerStyling?.flexDirection === 'horizontal'
            ? 'row'
            : 'column',
      }}
      containerClassName={`${buttonContainerClassName} ${
        classes.childContainer
      } ${addMarginTop ? classes.marginTopCustom : ''}`}
    >
      {children}
    </CustomContainer>
  );
}

export function Button({ buttonChild, buttonsDirection }) {
  const classes = useStyles();
  return buttonConfig(buttonChild, buttonsDirection)?.map((buttonBody) => (
    <CustomButton
      key={buttonBody.key}
      {...buttonBody}
      buttonClassName={classes.marginNone}
    />
  ));
}
