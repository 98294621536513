import React from 'react';
import { makeStyles } from '@material-ui/styles';
import isMobileDevice from '../../utils/isMobileDevice';

const useStyles = makeStyles((theme) => ({
  childrenContainer: {
    height: '100%',
    borderColor: '#9CA3AF',
    ...(theme.breakpoints.up('sm') &&
      !isMobileDevice() && {
        display: 'none',
      }),
  },
}));

export default function MobileView({ props }) {
  const classes = useStyles();

  return (
    <div {...props} className={classes.childrenContainer}>
      {props.children}
    </div>
  );
}
