/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/button-has-type */
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    borderRadius: '0.5rem',
    backgroundColor: '#000000',
    height: '210px',
    [theme.breakpoints.up('sm')]: { height: '157px' },
  },
  video: {
    position: 'absolute',
    borderRadius: '0.5rem',
    width: '100%',
    height: '100%',
  },
  pauseplayBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 10,
    boxShadow:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    transform: 'translate(-50%, -50%)',
  },
  btnIcon: {
    width: '3.5rem',
    height: '3.5rem',
    color: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(3.5rem * 0.78)',
      height: 'calc(3.5rem * 0.78)',
    },
  },
}));

export default function CustomVideo({
  videoContainerProps,
  videoContainerClassName,
  videoProps,
  videoClassName,
  videoUrl,
  videoAlt,
}) {
  const classes = useStyles();

  const [playing, setPlaying] = React.useState(false);
  const [playIcon, setPlayIcon] = React.useState(false);
  const [style, setStyle] = React.useState({ display: 'none' });

  const handlePlayPause = () => {
    setPlaying(!playing);
    setPlayIcon(!playIcon);
  };

  const handleEnded = () => {
    setPlaying(false);
    setPlayIcon(true);
  };

  const handlePlay = () => {
    setPlaying(true);
    setPlayIcon(false);
  };

  const handleReady = () => {
    setPlaying(false);
    setPlayIcon(true);
  };

  const classNameProps = {
    btn_display: style.display === 'none' ? 'hidden' : 'flex',
  };

  return (
    <>
      <div
        {...videoContainerProps}
        className={`${videoContainerClassName} ${classes.videoContainer}`}
        onMouseEnter={() => {
          setStyle({ display: 'block' });
        }}
        onMouseLeave={() => {
          setStyle({ display: 'none' });
        }}
      >
        <ReactPlayer
          className={`${videoClassName} ${classes.video}`}
          url={videoUrl}
          alt={videoAlt}
          playing={playing}
          onReady={handleReady}
          onPlay={handlePlay}
          onEnded={handleEnded}
          width="100%"
          height="100%"
          {...videoProps}
          config={{
            youtube: {
              playerVars: { modestbranding: 1 },
            },
          }}
        />
        <div
          className={`${classNameProps.btn_display} ${classes.pauseplayBtn}`}
          onClick={handlePlayPause}
        >
          {playIcon === false ? (
            <></>
          ) : (
            <FaRegCirclePlay className={classes.btnIcon} />
          )}
        </div>
      </div>
    </>
  );
}
